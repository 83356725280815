import './bootstrap';

document.addEventListener('alpine:init', () => {
    Alpine.data('brico', () => ({
        mobile_menu_open: false,
        
        cart: {total:0, total_items: 0, items: []},
        cart_qty: 1,
        cart_open: false,

        changeImageProduct(id_image) {         
            var els = document.getElementsByClassName('imageProduct');
            
            Array.prototype.forEach.call(els, function(el) {
                el.classList.add("hidden");
            });

            document.getElementById('image-' + id_image).classList.remove("hidden");
        },

        updateDisplayCart(data) {
            this.cart = data;

            // On met à jour le panier du header
            document.getElementById('headerTotalItems').innerHTML = data.total_items;
        },

        showCart() {
            var self = this;
            self.cart_open = true;

            axios.get('/panier/get')
                .then(function (response) {
                    self.updateDisplayCart(response.data);
                });
        },

        addToCart(ref_sdp) {
            var self = this;

            self.cart_open = true;
            var qty = (!isNaN(self.cart_qty) ? self.cart_qty : 1);

            // Envoi de la demande d'ajout au panier
            axios.get('/panier/add/' + ref_sdp + '/' + qty)
                .then(function (response) {
                    self.updateDisplayCart(response.data);
                });
        },

        incrementQty() {
            this.cart_qty++;
        },

        decrementQty() {
            if(this.cart_qty > 1) {
                this.cart_qty--;
            }
        },

        incrementCartItem(item) {
            item.qty++;
            this.updateCartItem(item);
        },

        decrementCartItem(item) {
            item.qty--;
            this.updateCartItem(item);
        },

        updateCartItem(item) {
            var self = this;

            axios.get('/panier/update/' + item.ref_sdp + '/' + item.qty)
            .then(function (response) {
                self.updateDisplayCart(response.data);
            });
        },

        deleteCartItem(item) {
            var self = this;

            axios.get('/panier/delete/' + item.ref_sdp)
            .then(function (response) {
                self.updateDisplayCart(response.data);
            });
        },

        submitFacets(need_page1 = true) {
            if(need_page1 && document.getElementById('formFacetsPage') !== null) {
                document.getElementById('formFacetsPage').value = 1;
            }

            var formData = new FormData(document.getElementById('formFacets'));

            axios.post('/facets', formData)
            .then(function (response) {
                if(document.getElementById('categoryfacetswraper') !== null) {
                    document.getElementById('categoryfacetswraper').innerHTML = response.data;
                    window.scrollTo({top: document.getElementById('categoryfacetswraper').offsetTop, behavior: 'smooth'});
                }
            });
        },

        changePage(page) {
            if(document.getElementById('formFacetsPage') !== null) {
                document.getElementById('formFacetsPage').value = page;
                this.submitFacets(false);
            }
        },
    }));

    Alpine.data('commande', (initialCarrier = 0, cart_total = 0, cart_shipping = 0, initialRelaySelected = '') => ({
        
        carrier: initialCarrier,
        cart_total: cart_total,
        cart_shipping: cart_shipping,

        modal_carrier: '',
        relays: [],
        relay_selected: initialRelaySelected,

        search_postal_code: '',
        search_city: '',

        changeCarrier(id_carrier, cart_shipping = 0, show_modal = '') {
            var self = this;

            self.cart_shipping = cart_shipping;
            self.carrier = id_carrier;

            axios.get('/panier/carrier/' + id_carrier).then(response => {
                if(show_modal != '')
                {
                    self.modal_carrier = show_modal;
                    self.launchModalCarrier();
                }
            });     
        },

        closeModalCarrier() {
            this.modal_carrier = '';
        },

        launchModalCarrier() {
            var self = this;

            // On charge les points relais à afficher
            var method = (this.modal_carrier == 'modalRelaisColissimo' ? 'relaiscolissimo' : 'drive');

            axios.get('/panier/' + method + '/get', {
                params: {
                    code_postal: self.search_postal_code,
                    ville: self.search_city  
                }
            })
            .then(function (response) {
                self.relays = response.data;
            });
        },

        selectRelayCarrier(data_relay) {
            var self = this;
            var method = (this.modal_carrier == 'modalRelaisColissimo' ? 'relaiscolissimo' : 'drive');
            self.relay_selected = data_relay.name + ' - ' + data_relay.city;

            axios.post('/panier/' + method + '/set', {
                relay: data_relay
            }).then(response => {
                if(self.refresh) {
                    location.reload();
                }
            });

            this.closeModalCarrier();
        },
    }));
});

import Alpine from 'alpinejs';
Alpine.start();